import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
}

const SEO = ({ title, description, image, article }: SEOProps) => {
  const query = graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
          defaultImage: image
        }
      }
    }
  `;

  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title ? title :  defaultTitle,
    description: description ? description : defaultDescription,
    image: image ? image: defaultImage,
    url: `${siteUrl}${pathname}`,
  };

  const schemaOrgJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    author: 'Kuben VGS',
    url: 'https://karkalk.no/',
    name: 'Kuben VGS',
    logo: `https://karkalk.no/preview_img.jpg`,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '23 46 57 00',
      contactType: 'Customer service',
    },
  };

  return (
    <Helmet title={seo.title} titleTemplate={title ? titleTemplate : ''}>
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;

import React from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from '../images/logo.svg';
import styles from './Nav.module.css';
import SEO from './SEO';

interface Props {

}

const Nav:React.FC<Props> = () => {
  return (
  <>
    <SEO />
    <Navbar className={styles.navbar}>
    <div className={styles.flex}>
      <div>
    <a href={'https://kuben.vgs.no/'} target="_blank">
      <Navbar.Brand className={styles.nav}>
        <h1 className={styles.title}>Kuben videregående skole</h1>
    </Navbar.Brand>
  </a>
  </div>
  <div>
    <a href={'https://kuben.vgs.no/'} target="_blank">
      <Navbar.Brand className={styles.nav}>
        <img className={styles.logo} src={Logo} alt="Oslo kommuneskjold" />
    </Navbar.Brand>
  </a>
  </div>
  </div>

  </Navbar>
</>
  )
}

export default Nav
